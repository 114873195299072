import {
  BankAccountType,
  BaseError,
  Country,
  CountryCode,
  CurrencyCode,
  PayoutMethodType,
  RecipientType,
  Rules,
  formatCountry,
  formatCurrency,
  getBankRuleProps,
  getBankRules,
  hasBank as hasBankName,
  isRequiredToActivate,
  isValidIbanSwiftAccountCountry,
} from "@trolley/common-frontend";
import deepEqual from "fast-deep-equal";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  CurrencyDisplay,
  Divider,
  Form,
  GreyBox,
  Grid,
  Icon,
  Loader,
  Modal,
  Notification,
  Text,
} from "components";
import { BankInfoDisplay, DisabledNotice } from "features";
import { PATHS } from "pages/App/routes";
import {
  FIELDS_REQUIRING_BANK_ACCOUNT_LOOKUP,
  FIELDS_REQUIRING_BANK_LOOKUP,
  getBankFieldRulesIndex,
  getDefaultBankRule,
  getInitialValues,
  getMainCurrency,
  mapBankAccountQuery,
} from "pages/PayoutMethod2/BankTransfer";
import { AccountVerificationQuery, BankQuery } from "store/actions/bankInfo";
import {
  BankTransferAccountUpdate,
  Recipient,
  RecipientAccount,
  addPayoutMethod,
  updatePayoutMethod,
} from "store/actions/recipient";
import { loadAsyncRecipientAccountConfig } from "store/actions/recipientAccountConfig";
import { useBankCodes } from "store/hooks/bankCodes";
import { useAccountVerification, useBankInfo } from "store/hooks/bankInfo";
import { useIframeConfig } from "store/hooks/config";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { BaseStatus } from "store/reducers/standardReducer";
import { FormatMessage, IntlMessageKeys, IntlShape, Translator, useIntl } from "utils/context";
import { handleFormErrors, omitMaskedValues, pick } from "utils/helpers";
import PayoutFooter from "./PayoutFooter";
import { useBankTransferFees } from "store/hooks/fees";
import AccountVerificationResultAlert from "pages/PayoutMethod2/BankTransfer/AccountVerificationResultAlert";

function getImportantMessages(translator: Translator, country: CountryCode) {
  const messages = {
    [CountryCode.US]: [
      <li key="mustProvideACH">{translator({ id: "containers.bankPayoutMethod.mustProvideACH" })}</li>,
    ],
    [CountryCode.RU]: [
      <li key="ruImportant1">{translator({ id: "containers.bankPayoutMethod.ruImportant1" })}</li>,
      <li key="ruImportant2">{translator({ id: "containers.bankPayoutMethod.ruImportant2" })}</li>,
    ],
  };

  if (country !== CountryCode.US && country !== CountryCode.RU) return null;

  return messages[country];
}

export function reEscape(s: string) {
  return s.replace(/[-[\]{}()*+?.,\\^$|]/g, "\\$&");
}

export function getHolderNameWarnings(
  formatMessage: Translator,
  recipient: Recipient,
  accountHolderName: string = "",
  part?: "first" | "last",
) {
  const lowerCaseName = accountHolderName.toLowerCase();

  const warnings = [];
  if (
    accountHolderName &&
    recipient.type === "individual" &&
    !isValidName(
      part === "last" ? "" : recipient.firstName,
      part === "first" ? "" : recipient.lastName,
      accountHolderName,
    )
  ) {
    warnings.push({
      type: "nameMismatch",
      warningText: formatMessage({
        id: "containers.bankPayoutMethod.accountHolderName.nameMatchWarning",
      }),
      popupText: formatMessage({
        id: "containers.bankPayoutMethod.accountHolderName.popupText1",
      }),
    });
  }

  if (hasBankName.test(lowerCaseName)) {
    warnings.push({
      type: "hasBank",
      warningText: formatMessage({
        id: "containers.bankPayoutMethod.accountHolderName.containsBank",
      }),
      popupText: formatMessage({
        id: "containers.bankPayoutMethod.accountHolderName.bankPopupText1",
      }),
    });
  }

  return warnings;
}

function buildDownCurrencies(
  countryCurrencies: Country.CountryCurrency[] | undefined,
  formatMessage: Translator,
): { label: string; value: string }[] {
  const options: { label: string; value: string }[] = [];

  (countryCurrencies ?? []).forEach((item) => {
    const currencyLabel = formatCurrency(item.currency, formatMessage);
    const optionItem = {
      label: currencyLabel ? `${currencyLabel} (${item.currency})` : item.currency,
      value: item.currency,
    };

    if (item.isPrimary) {
      options.unshift(optionItem);
    } else {
      options.push(optionItem);
    }
  });

  return options;
}

export function isValidName(firstName: string, lastName: string, holderName: string) {
  const firstNameParts = (firstName ?? "").split(/(?:\s|-)/);
  const lastNameParts = (lastName ?? "").split(/(?:\s|-)/);
  const firstRe = `\\b(${firstNameParts.map(reEscape).join("|")})\\b`;
  const lastRe = `\\b(${lastNameParts.map(reEscape).join("|")})\\b`;
  const regex = new RegExp(`(${firstRe}.*${lastRe}|${lastRe}.*${firstRe})`, "ig");

  return regex.test(holderName);
}

type FormFields = {
  country: CountryCode;
  currency: CurrencyCode;
  iban?: string;
  bankAccountType?: BankAccountType;
  accountNum?: string;
  swiftBic?: string;
  branchId?: string;
  bankId?: string;
  bankName?: string;
  bankAddress?: string;
  bankCity?: string;
  bankCodeMappingId?: string; // should be number, but <Select> transforms it to string.
  bankRegionCode?: string;
  accountHolderName: string;
  accountHolderFirstName?: string;
  accountHolderLastName?: string;
};

type AllProps = {
  account: RecipientAccount | undefined;
  currencies: Partial<Record<CountryCode, Country.CountryCurrency[]>>;
};

export default function BankTransfer({ account, currencies }: AllProps) {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const config = useIframeConfig();
  const recipient = useRecipient();
  const merchant = useMerchant();
  const [activate, setActivate] = useState(false);
  const [bankQuery, setBankQuery] = useState<BankQuery | undefined>();
  const [accountQuery, setAccountQuery] = useState<AccountVerificationQuery | undefined>();
  const { data: bankInfo, status: bankInfoStatus } = useBankInfo(bankQuery);
  const {
    data: accountVerifResult,
    status: accountVerifStatus,
    error: accountVerificationError,
  } = useAccountVerification(accountQuery, recipient?.type as RecipientType);
  const [submitting, setSubmitting] = useState(false);
  const [showHolderNamePopup, setShowHolderNamePopup] = useState<"save-primary" | "save" | undefined>(undefined);
  const [bankRulesIndex, setBankRulesIndex] = useState(0);
  const bankPayoutMethod = merchant?.enabledPayoutMethods.find(
    (pm) => pm.integration === PayoutMethodType.BANKTRANSFER,
  );
  const deliveryEstimate = account?.deliveryBusinessDaysEstimate || bankPayoutMethod?.deliveryBusinessDaysEstimate || 0;
  const payoutCountry: CountryCode = Form.useWatch("country", form) || account?.country;
  const currency: CurrencyCode = Form.useWatch("currency", form) || account?.currency;
  const { data: bankCodes } = useBankCodes(payoutCountry, currency);
  const [requiresAccountVerification, setRequiresAccountVerification] = useState(false);

  const [allRules, setAllRules] = useState<
    {
      label: string;
      bankFields: Rules;
    }[]
  >(
    account
      ? getDefaultBankRule(account.country, account.currency)
      : getDefaultBankRule(
          recipient?.address.country,
          recipient?.address.country && getMainCurrency(currencies[recipient.address.country]),
        ),
  );

  const countryRule = useMemo(() => allRules[bankRulesIndex]?.bankFields || [], [allRules, bankRulesIndex]);

  useEffect(() => {
    const requiresVerif = !!merchant?.features?.accountVerificationUk && recipient?.address.country === CountryCode.GB;
    if (requiresVerif !== requiresAccountVerification) {
      setRequiresAccountVerification(requiresVerif);
    }
  }, [merchant, recipient]);

  /**
   * when a country or currency changes, we fetch the new bank rules (BankFieldType[])
   * */
  useEffect(() => {
    if (payoutCountry && currency && recipient) {
      loadAsyncRecipientAccountConfig({
        recipientId: recipient.id,
        accountType: PayoutMethodType.BANKTRANSFER,
        countryCode: payoutCountry,
        currencyCode: currency,
      })
        .then((recipientAccountConfig) => {
          updateAllRules(
            recipientAccountConfig?.requiredFields.length
              ? recipientAccountConfig?.requiredFields
              : getDefaultBankRule(payoutCountry, currency),
          );
        })
        .catch(() => {
          updateAllRules(getDefaultBankRule(payoutCountry, currency));
        });
    }
  }, [payoutCountry, currency]);

  function updateAllRules(allNewRules: ReturnType<typeof getBankRules>) {
    setAllRules(allNewRules);

    if (!deepEqual(allNewRules, allRules)) {
      setAllRules(allNewRules);
    }

    if (account) {
      const ruleIndex = getBankFieldRulesIndex(allNewRules, account);
      setBankRulesIndex(ruleIndex > 0 ? ruleIndex : 0);
    } else {
      setBankRulesIndex(0);
    }
  }

  // matching splitting logic with backend. everything before last space is first name.
  function replaceName(accountHolderName: string) {
    if (recipient?.type === RecipientType.INDIVIDUAL) {
      const lastSpaceIndex = accountHolderName.lastIndexOf(" ");

      const accountHolderFirstName = accountHolderName.substring(0, lastSpaceIndex);
      const accountHolderLastName = accountHolderName.substring(lastSpaceIndex + 1);

      if (accountHolderFirstName && accountHolderLastName) {
        form.setFieldValue("accountHolderFirstName", accountHolderFirstName);
        form.setFieldValue("accountHolderLastName", accountHolderLastName);
        onValuesChange({
          accountHolderFirstName,
          accountHolderLastName,
        });
      }
    }

    if (recipient?.type === RecipientType.BUSINESS) {
      form.setFieldValue("accountHolderName", accountHolderName);
      onValuesChange({
        accountHolderName,
      });
    }
  }

  function onValuesChange(changedValues: Partial<FormFields>) {
    const [field] = Object.keys(changedValues);
    if (countryRule.length && FIELDS_REQUIRING_BANK_LOOKUP.includes(field)) {
      onFindBankDetailsDebounce(countryRule);
    }
    if (requiresAccountVerification && countryRule.length && FIELDS_REQUIRING_BANK_ACCOUNT_LOOKUP.includes(field)) {
      onFindAccountDetailsDebounce(countryRule);
    }
  }

  const onFindBankDetailsDebounce = useCallback(
    debounce((countryRule: Rules) => {
      const fieldsValue = form.getFieldsValue();
      const errors = form.getFieldsError(FIELDS_REQUIRING_BANK_LOOKUP);
      const validValues = countryRule.every((rule) => {
        if (!FIELDS_REQUIRING_BANK_LOOKUP.includes(rule)) {
          return true;
        }

        if (!fieldsValue[rule]) {
          return false;
        }

        return !fieldsValue[rule]?.includes("*") || rule === "accountNum";
      });

      const hasErrors = Object.values(errors).some((item) => item.errors.length > 0); // Errors on fields that matters to the bank lookup
      if (!hasErrors && validValues) {
        // every field requiring a bank lookup must have a value
        setBankQuery(pick(fieldsValue, FIELDS_REQUIRING_BANK_LOOKUP));
      } else {
        setBankQuery(undefined);
      }
    }, 650),
    [form],
  );

  const onFindAccountDetailsDebounce = useCallback(
    debounce((countryRule: Rules) => {
      const fieldsValue = form.getFieldsValue();
      const errors = form.getFieldsError(FIELDS_REQUIRING_BANK_ACCOUNT_LOOKUP);
      const validValues = [
        ...countryRule,
        ...(recipient?.type === RecipientType.INDIVIDUAL
          ? ["accountHolderFirstName", "accountHolderLastName"]
          : ["accountHolderName"]),
      ].every((rule) => {
        if (!FIELDS_REQUIRING_BANK_ACCOUNT_LOOKUP.includes(rule)) {
          return true;
        }

        return !!fieldsValue[rule];
      });

      const hasErrors = Object.values(errors).some((item) => item.errors.length > 0); // Errors on fields that matters to the bank lookup
      if (!hasErrors && validValues && recipient?.id) {
        const mappedQuery = mapBankAccountQuery(fieldsValue, recipient.id, CountryCode.GB, account?.id);
        setAccountQuery(mappedQuery ?? undefined);
      } else {
        setAccountQuery(undefined);
      }
    }, 650),
    [form],
  );

  if (!recipient) {
    return null;
  }

  async function onSubmit(values: FormFields) {
    if (recipient && !submitting) {
      setSubmitting(true);

      if (
        !showHolderNamePopup &&
        getHolderNameWarnings(formatMessage, recipient, values.accountHolderName).length > 0
      ) {
        // Give warning that account holder name is not the same as profile name
        setShowHolderNamePopup(activate ? "save-primary" : "save");
      } else {
        const bankCodeMappingId = values.bankCodeMappingId ? parseInt(values.bankCodeMappingId, 10) : undefined;

        const data: BankTransferAccountUpdate = {
          ...values,
          bankCodeMappingId,
          type: PayoutMethodType.BANKTRANSFER,
          ...(values.accountHolderFirstName && values.accountHolderFirstName
            ? { accountHolderName: `${values.accountHolderFirstName} ${values.accountHolderLastName}` }
            : {}),
        };

        if (activate || showHolderNamePopup === "save-primary") {
          data.primary = true;
        }
        try {
          if (account?.recipientAccountId) {
            await updatePayoutMethod(recipient.id, account.recipientAccountId, omitMaskedValues(data));
            setShowHolderNamePopup(undefined);
            history.push(PATHS.HOME);
          } else {
            await addPayoutMethod(recipient.id, data);
            setShowHolderNamePopup(undefined);
            history.push(PATHS.PAYOUT_COMPLETE);
          }
        } catch (errors) {
          setShowHolderNamePopup(undefined);
          handleFormErrors(
            errors?.map?.((error: BaseError) => {
              if (error?.field === "currency" && error.code === "invalid_field") {
                // Overriding currency error as we know the cause of it.
                // This also allows us to use translation.
                return {
                  ...error,
                  message: formatMessage(
                    { id: "containers.bankPayoutMethod.currency.invalid_for_recipientType" },
                    {
                      recipientType: formatMessage({
                        id:
                          recipient.type === "indvidual"
                            ? "containers.info.type.individual"
                            : "containers.info.type.business",
                      }),
                      currencyCode: formatCurrency(values.currency, formatMessage),
                      country: formatCountry(values.country, formatMessage),
                    },
                  ),
                };
              }

              return error;
            }) ?? errors,
            form,
          );
        }
      }
      setSubmitting(false);
    }
  }

  function submitCall() {
    form.submit();
  }

  return (
    <Loader spinning={submitting}>
      <Container>
        <Form<FormFields>
          form={form}
          initialValues={getInitialValues(account, recipient, currencies)}
          onFinish={onSubmit}
          validateTrigger={["onSubmit", "onChange"]}
          onValuesChange={onValuesChange}
        >
          <Form.Control<FormFields> dependencies={["currency", "country"]}>
            {({ value: { country, currency } }) => {
              const dropDownCurrencies = buildDownCurrencies(currencies[country as CountryCode], formatMessage);
              const countryHasCurrencies = dropDownCurrencies.length > 0;
              const selectableCountries = [
                recipient.address.country as CountryCode,
                ...(!account &&
                merchant?.features?.allowUSBankTransferAccount &&
                recipient.address.country !== CountryCode.US
                  ? [CountryCode.US]
                  : []),
              ];
              const accountHolderNameRules = getBankRuleProps("accountHolderName", undefined, {}, formatMessage);

              return (
                <>
                  <Grid>
                    {config.showFees && (
                      <Grid.Item xs={24} sm={12}>
                        <Text size="small">
                          {formatMessage({
                            id: "containers.bankPayoutMethod.processingTimes.title",
                          })}
                          <b>
                            {deliveryEstimate < 1
                              ? formatMessage({ id: "containers.payoutMethod.processingTimes.sameDay" })
                              : formatMessage(
                                  {
                                    id: "containers.payoutMethod.processingTimes.bussinessDaysEstimate",
                                  },
                                  { from: deliveryEstimate, to: deliveryEstimate + 1 },
                                )}
                          </b>{" "}
                        </Text>

                        {recipient?.address.country && (
                          <EstimatedFees
                            country={recipient.address.country}
                            sourceCurrency={merchant?.primaryCurrency ?? undefined}
                            targetCurrency={currency}
                            formatMessage={formatMessage}
                          />
                        )}
                      </Grid.Item>
                    )}
                  </Grid>
                  <Divider transparent />

                  {account?.status === "disabled" && <DisabledNotice account={account} />}
                  {countryHasCurrencies && (
                    <Grid>
                      <Grid.Item xs={24} sm={12}>
                        <Grid padding="none">
                          {selectableCountries.length > 1 ? (
                            <Grid.Item xs={24} key="bank_country">
                              <Form.Field
                                name="country"
                                label={formatMessage({
                                  id: "containers.bankPayoutMethod.country.title",
                                })}
                                rules={[
                                  {
                                    required: true,
                                    message: formatMessage({
                                      id: "containers.bankPayoutMethod.country.required",
                                    }),
                                  },
                                ]}
                              >
                                <Form.SelectCountry
                                  name="country"
                                  onChange={(e) => {
                                    setBankRulesIndex(0);
                                    setBankQuery(undefined);
                                    const country = e.target.value;
                                    const payoutCountryCurrencies =
                                      (country && currencies[country as CountryCode]) || [];
                                    form.setFieldsValue({
                                      currency: getMainCurrency(payoutCountryCurrencies),
                                    });
                                  }}
                                  type="banking"
                                  selectableCountries={selectableCountries}
                                />
                              </Form.Field>
                            </Grid.Item>
                          ) : (
                            <Form.Field name="country" /> // This line is required to retain the country in the form
                          )}

                          <Grid.Item xs={24} key="bank_currency">
                            <Form.Field
                              name="currency"
                              label={formatMessage({
                                id: "containers.bankPayoutMethod.currency.title",
                              })}
                              rules={[
                                {
                                  required: true,
                                  message: formatMessage({
                                    id: "containers.bankPayoutMethod.currency.required",
                                  }),
                                },
                              ]}
                            >
                              {({ value, onChange }) => {
                                const disabled = !!account?.id; // disable currency if account is already saved;

                                return (
                                  <Form.Select
                                    name="currency"
                                    value={value}
                                    options={dropDownCurrencies}
                                    disabled={disabled}
                                    readOnly={!disabled && dropDownCurrencies.length < 2} // only 1 option. so readOnly
                                    onChange={async (e) => {
                                      setBankRulesIndex(0);
                                      setBankQuery(undefined);
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            </Form.Field>
                          </Grid.Item>
                          {!account?.id && allRules.length > 1 && (
                            <Grid.Item key="bank_field_accountType" xs={24}>
                              <Form.Radio
                                name="accountType"
                                options={allRules.map((type, index) => ({
                                  label: type.label,
                                  value: String(index),
                                }))}
                                inline
                                value={String(bankRulesIndex)}
                                onChange={(e) => {
                                  setBankRulesIndex(Number(e.target.value));
                                  setBankQuery(undefined);
                                }}
                                style={{ marginBottom: "16px" }}
                              />
                            </Grid.Item>
                          )}

                          {countryRule.map((field, fieldIndex) => (
                            <Form.Control key={field} dependencies={[field, "bankId"]}>
                              {({ value: { bankId, [field]: value } }) => {
                                const inputProps = getBankRuleProps(
                                  field,
                                  value,
                                  {
                                    country,
                                    currency,
                                    bankId,
                                  },
                                  formatMessage,
                                );

                                if (inputProps) {
                                  const bankRegionRules =
                                    field === "bankCity" &&
                                    getBankRuleProps(
                                      "bankRegionCode",
                                      undefined,
                                      {
                                        country,
                                        currency,
                                      },
                                      formatMessage,
                                    );

                                  return (
                                    <React.Fragment key={field}>
                                      <Grid.Item key={`bank_field_${field}`} xs={24}>
                                        <Form.Field
                                          name={field}
                                          label={inputProps.label}
                                          dependencies={FIELDS_REQUIRING_BANK_LOOKUP}
                                          tooltip={inputProps.tooltip}
                                          validateFirst
                                          normalize={inputProps.normalize}
                                          hint={inputProps.hint}
                                          optional={!inputProps.rules?.find((r) => r.required)}
                                          rules={[
                                            ...(inputProps.rules || []),
                                            ({ getFieldValue }) => ({
                                              async validator(_: any, value: string) {
                                                // custom validation for IBAN/SWIFT account
                                                if (
                                                  ["iban", "swiftBic"].includes(field) &&
                                                  value &&
                                                  !value.includes("*")
                                                ) {
                                                  const accountCountryStr = String(value || "")
                                                    .substr(field === "iban" ? 0 : 4, 2)
                                                    .toLocaleUpperCase();
                                                  const accountCountry =
                                                    accountCountryStr in CountryCode
                                                      ? (accountCountryStr as CountryCode)
                                                      : undefined;

                                                  if (
                                                    accountCountry &&
                                                    !isValidIbanSwiftAccountCountry({
                                                      payoutCountry: getFieldValue("country") as CountryCode,
                                                      payoutCurrency: getFieldValue("currency") as CurrencyCode,
                                                      accountCountry,
                                                      accountCountryAcceptedCurrencies:
                                                        currencies[accountCountry]?.map((v) => v.currency) || [],
                                                    })
                                                  ) {
                                                    throw formatMessage(
                                                      {
                                                        id: `containers.bankPayoutMethod.${field}Country` as IntlMessageKeys,
                                                      },
                                                      {
                                                        country: formatCountry(getFieldValue("country"), formatMessage),
                                                      },
                                                    );
                                                  }
                                                }
                                              },
                                            }),
                                          ]}
                                        >
                                          {inputProps.options ? (
                                            <Form.Select
                                              placeholder={inputProps.placeholder}
                                              options={
                                                field === "bankCodeMappingId"
                                                  ? bankCodes.map((code) => ({
                                                      label: code.bankName,
                                                      value: code.id,
                                                    }))
                                                  : inputProps.options
                                              }
                                            />
                                          ) : (
                                            <Form.Input
                                              name={field}
                                              placeholder={inputProps.placeholder}
                                              suffix={
                                                inputProps.warning ? (
                                                  <div>
                                                    <Icon
                                                      type="exclamation-triangle"
                                                      color="warning"
                                                      tooltip={inputProps.warning}
                                                    />
                                                  </div>
                                                ) : (
                                                  <span />
                                                )
                                              }
                                            />
                                          )}
                                        </Form.Field>
                                      </Grid.Item>

                                      {!!bankRegionRules && (
                                        <Grid.Item key="bank_field_bankRegionCode" xs={24}>
                                          <Form.Field
                                            name="bankRegionCode"
                                            validateFirst
                                            label={bankRegionRules.label}
                                            normalize={bankRegionRules.normalize}
                                            rules={bankRegionRules.rules}
                                            optional={!bankRegionRules.rules?.find((r) => r.required)}
                                          >
                                            <Form.SelectRegion
                                              name="bankRegionCode"
                                              country={country}
                                              showEmptyOption={!bankRegionRules.rules?.find((r) => r.required)}
                                            />
                                          </Form.Field>
                                        </Grid.Item>
                                      )}
                                    </React.Fragment>
                                  );
                                }

                                return null;
                              }}
                            </Form.Control>
                          ))}
                          {accountHolderNameRules &&
                            (!requiresAccountVerification || recipient.type === RecipientType.BUSINESS) && (
                              <Grid.Item key="bank_field_accountHolderName" xs={24}>
                                <Form.Field
                                  name="accountHolderName"
                                  label={formatMessage({
                                    id: "containers.bankPayoutMethod.accountHolderName.title",
                                  })}
                                  tooltip={formatMessage({
                                    id: "containers.bankPayoutMethod.correctName",
                                  })}
                                  validateFirst
                                  normalize={accountHolderNameRules.normalize}
                                  rules={accountHolderNameRules.rules}
                                >
                                  {({ value: accountHolderName, onChange }) => {
                                    const holderNameWarnings = getHolderNameWarnings(
                                      formatMessage,
                                      recipient,
                                      accountHolderName,
                                    );

                                    return (
                                      <Form.Input
                                        value={accountHolderName}
                                        onChange={onChange}
                                        name="accountHolderName"
                                        placeholder="John Smith"
                                        suffix={
                                          holderNameWarnings[0] ? (
                                            <div>
                                              <Icon
                                                type="exclamation-triangle"
                                                color="warning"
                                                tooltip={holderNameWarnings[0].warningText}
                                              />
                                            </div>
                                          ) : (
                                            <span />
                                          )
                                        }
                                      />
                                    );
                                  }}
                                </Form.Field>
                              </Grid.Item>
                            )}
                          {accountHolderNameRules &&
                            requiresAccountVerification &&
                            recipient.type === RecipientType.INDIVIDUAL && (
                              <Grid.Item key="bank_field_accountHolderFirstName" xs={24}>
                                <Form.Field
                                  name="accountHolderFirstName"
                                  label={formatMessage({
                                    id: "containers.bankPayoutMethod.accountHolderFirstName.title",
                                  })}
                                  tooltip={formatMessage({
                                    id: "containers.bankPayoutMethod.correctFirstName",
                                  })}
                                  validateFirst
                                  normalize={accountHolderNameRules.normalize}
                                  rules={accountHolderNameRules.rules}
                                >
                                  {({ value: accountHolderFirstName, onChange }) => {
                                    const holderNameWarnings = getHolderNameWarnings(
                                      formatMessage,
                                      recipient,
                                      accountHolderFirstName,
                                      "first",
                                    );

                                    return (
                                      <Form.Input
                                        value={accountHolderFirstName}
                                        onChange={onChange}
                                        name="accountHolderFirstName"
                                        placeholder="John"
                                        suffix={
                                          holderNameWarnings[0] ? (
                                            <div>
                                              <Icon
                                                type="exclamation-triangle"
                                                color="warning"
                                                tooltip={holderNameWarnings[0].warningText}
                                              />
                                            </div>
                                          ) : (
                                            <span />
                                          )
                                        }
                                      />
                                    );
                                  }}
                                </Form.Field>
                              </Grid.Item>
                            )}
                          {accountHolderNameRules &&
                            requiresAccountVerification &&
                            recipient.type === RecipientType.INDIVIDUAL && (
                              <Grid.Item key="bank_field_accountHolderLastName" xs={24}>
                                <Form.Field
                                  name="accountHolderLastName"
                                  label={formatMessage({
                                    id: "containers.bankPayoutMethod.accountHolderLastName.title",
                                  })}
                                  tooltip={formatMessage({
                                    id: "containers.bankPayoutMethod.correctLastName",
                                  })}
                                  validateFirst
                                  normalize={accountHolderNameRules.normalize}
                                  rules={accountHolderNameRules.rules}
                                >
                                  {({ value: accountHolderLastName, onChange }) => {
                                    const holderNameWarnings = getHolderNameWarnings(
                                      formatMessage,
                                      recipient,
                                      accountHolderLastName,
                                      "last",
                                    );

                                    return (
                                      <Form.Input
                                        value={accountHolderLastName}
                                        onChange={onChange}
                                        name="accountHolderLastName"
                                        placeholder="Smith"
                                        suffix={
                                          holderNameWarnings[0] ? (
                                            <div>
                                              <Icon
                                                type="exclamation-triangle"
                                                color="warning"
                                                tooltip={holderNameWarnings[0].warningText}
                                              />
                                            </div>
                                          ) : (
                                            <span />
                                          )
                                        }
                                      />
                                    );
                                  }}
                                </Form.Field>
                              </Grid.Item>
                            )}
                        </Grid>
                        {requiresAccountVerification && recipient && accountVerifStatus && (
                          <Grid padding="none">
                            <Grid.Item xs={24}>
                              <Loader spinning={accountVerifStatus === BaseStatus.LOADING}>
                                {(accountVerifResult || accountVerificationError) && (
                                  <AccountVerificationResultAlert
                                    result={accountVerifResult}
                                    recipientType={recipient.type as RecipientType}
                                    replaceName={replaceName}
                                    error={accountVerificationError}
                                  />
                                )}
                              </Loader>
                            </Grid.Item>
                          </Grid>
                        )}
                      </Grid.Item>
                      {!countryRule.includes("bankCodeMappingId") ? (
                        <Grid.Item xs={24} sm={12}>
                          {(!requiresAccountVerification || bankInfoStatus === BaseStatus.LOADED) && (
                            <BankInfoDisplay
                              value={bankInfo}
                              status={bankInfoStatus}
                              recipientAccount={account}
                              showAddress={!countryRule.includes("swiftBic")}
                            />
                          )}
                          {(!requiresAccountVerification || bankInfoStatus === BaseStatus.LOADED) && (
                            <Notification
                              title={formatMessage({
                                id: "containers.bankPayoutMethod.important",
                              })}
                              type="warning"
                            >
                              <ul>
                                {country && getImportantMessages(formatMessage, country)}
                                {isRequiredToActivate("govId", country, recipient.type as RecipientType) && (
                                  <li>
                                    {formatMessage({
                                      id: "containers.bankPayoutMethod.govIdCorrect",
                                    })}
                                  </li>
                                )}
                                <li>
                                  {formatMessage({
                                    id: "containers.bankPayoutMethod.correctName",
                                  })}
                                </li>
                                {currency && (
                                  <li>
                                    {formatMessage(
                                      {
                                        id: "containers.bankPayoutMethod.correctCurrency",
                                      },
                                      {
                                        currency: (
                                          <b>
                                            {formatCurrency(currency, formatMessage)} {currency}
                                          </b>
                                        ),
                                      },
                                    )}
                                  </li>
                                )}
                              </ul>
                            </Notification>
                          )}
                        </Grid.Item>
                      ) : null}
                    </Grid>
                  )}
                  <PayoutFooter
                    setBusy={setSubmitting}
                    busy={submitting}
                    account={account}
                    onSave={() => {
                      setActivate(false);
                    }}
                    onSaveActivate={() => {
                      setActivate(true);
                    }}
                    disabled={
                      (!countryRule.includes("bankCodeMappingId") &&
                        (bankInfoStatus === BaseStatus.ERROR || (!bankInfo && !account))) ||
                      (requiresAccountVerification &&
                        (accountVerifStatus === BaseStatus.ERROR || (!accountVerifResult && !account)))
                    }
                    disabledTooltip={formatMessage({
                      id: "containers.bankPayoutMethod.noLookupTooltip",
                    })}
                    accountVerificationResult={accountVerifResult}
                    submitCall={submitCall}
                  />
                  <Form.Control dependencies={["accountHolderName"]}>
                    {({ value: { accountHolderName } }) => {
                      const holderNameWarnings = getHolderNameWarnings(formatMessage, recipient, accountHolderName);

                      return (
                        holderNameWarnings.length > 0 && (
                          <NameWarningPopup
                            visible={!!showHolderNamePopup}
                            onClose={() => {
                              setShowHolderNamePopup(undefined);
                            }}
                            onOk={async () => {
                              form.submit();
                            }}
                            submitting={submitting}
                            holderNameWarnings={holderNameWarnings}
                            accountHolderName={accountHolderName}
                            recipient={recipient}
                          />
                        )
                      );
                    }}
                  </Form.Control>
                </>
              );
            }}
          </Form.Control>
        </Form>
      </Container>
    </Loader>
  );
}

type NameWarningProps = {
  visible: boolean;
  onClose(): void;
  onOk(): Promise<void>;
  submitting?: boolean;
  accountHolderName?: string;
  recipient: Recipient;
  holderNameWarnings: ReturnType<typeof getHolderNameWarnings>;
};

export function NameWarningPopup({
  visible,
  onClose,
  onOk,
  submitting,
  holderNameWarnings,
  accountHolderName,
  recipient,
}: NameWarningProps) {
  const { formatMessage } = useIntl();

  return (
    <Modal
      open={!!visible}
      onCancel={onClose}
      onOk={onOk}
      confirmLoading={submitting}
      cancelText="Back"
      okText="Save"
      title={formatMessage({
        id: "containers.bankPayoutMethod.accountHolderName.popupTitle",
      })}
    >
      <div>
        {holderNameWarnings.length > 1 ? (
          <ul style={{ paddingLeft: "16px" }}>
            {holderNameWarnings.map((w) => (
              <li key={w.type}>
                <Text size="small">{w.popupText}</Text>
              </li>
            ))}
          </ul>
        ) : (
          <Text>{holderNameWarnings[0]?.popupText}</Text>
        )}

        <GreyBox margin="small">
          <Grid padding="medium">
            <Grid.Item xs={24} sm={holderNameWarnings.find((w) => w.type === "nameMismatch") ? 12 : 24}>
              <Text size="small">
                {formatMessage({
                  id: "containers.bankPayoutMethod.accountHolderName.holderName",
                })}
              </Text>
              <Text strong type="danger">
                {accountHolderName}
              </Text>
            </Grid.Item>
            {holderNameWarnings.find((w) => w.type === "nameMismatch") && (
              <Grid.Item xs={24} sm={12}>
                <Text size="small">
                  {formatMessage({
                    id: "containers.bankPayoutMethod.accountHolderName.profileName",
                  })}
                </Text>
                <Text strong>{`${recipient.firstName} ${recipient.lastName}`}</Text>
              </Grid.Item>
            )}
            <Grid.Item xs={24}>
              {formatMessage({
                id: "containers.bankPayoutMethod.accountHolderName.popupText2",
              })}
            </Grid.Item>
          </Grid>
        </GreyBox>
      </div>
    </Modal>
  );
}

function EstimatedFees({
  sourceCurrency,
  targetCurrency,
  country,
  formatMessage,
}: {
  sourceCurrency?: CurrencyCode;
  targetCurrency: CurrencyCode;
  country: CountryCode;
  formatMessage: IntlShape["formatMessage"] | FormatMessage;
}) {
  const fees = useBankTransferFees({
    country,
    sourceCurrency,
    targetCurrency,
  });

  if (!fees) return null;

  const { amount: estimatedFees, currency: estimatedFeesCurrency } = fees;

  return (
    <Text size="small">
      {formatMessage({
        id: "containers.payoutMethod.estimatedFees",
      })}
      :{" "}
      <b>
        <CurrencyDisplay value={estimatedFees} currency={estimatedFeesCurrency} />
      </b>
    </Text>
  );
}
