import { VerificationRejectedReasons, VerificationStatus } from "@trolley/common-frontend";
import { Icon, Status } from "components";
import React, { useMemo } from "react";
import { useIntl } from "utils/context";
import { getAccessibleEqColor, getAccessibleTextColor } from "utils/helpers";

interface Props {
  status: VerificationStatus;
  rejectedReason?: VerificationRejectedReasons;
}

type StatusType = "info" | "success" | "warning" | "critical" | "default";

export default function IdvStatusDisplay({ status, rejectedReason = VerificationRejectedReasons.OTHER }: Props) {
  const { formatMessage } = useIntl();

  const TagInfo = useMemo(
    () => ({
      [VerificationStatus.APPROVED]: {
        icon: <Icon type="check" style={{ color: getAccessibleTextColor("success") }} size="large" />,
        label: formatMessage({ id: "containers.trust.verificationStatus.approved" }),
        // color: "success",
        color: getAccessibleEqColor("success"),
        textColor: getAccessibleTextColor("success"),
        type: "success",
      },
      [VerificationStatus.PENDING]: {
        icon: <Icon type="clock" style={{ color: getAccessibleTextColor("info") }} size="large" />,
        label: formatMessage({
          id: "containers.trust.verificationStatus.pending",
        }),
        // color: "processing",
        color: getAccessibleEqColor("info"),
        textColor: getAccessibleTextColor("info"),
        type: "info",
      },
      [VerificationStatus.REJECTED]: {
        icon: (
          <Icon
            type="circle-exclamation"
            style={{
              color:
                rejectedReason === VerificationRejectedReasons.FRAUDULENT
                  ? getAccessibleTextColor("critical")
                  : getAccessibleTextColor("warning"),
            }}
            size="large"
          />
        ),
        label: `${formatMessage({ id: "containers.trust.verificationStatus.rejected" })} - ${formatMessage({
          id: `containers.trust.rejectedReasons.${rejectedReason}`,
        })}`,
        // color: "error",
        color:
          rejectedReason === VerificationRejectedReasons.FRAUDULENT
            ? getAccessibleEqColor("critical")
            : getAccessibleEqColor("warning"),
        textColor:
          rejectedReason === VerificationRejectedReasons.FRAUDULENT
            ? getAccessibleTextColor("critical")
            : getAccessibleTextColor("warning"),
        type: rejectedReason === VerificationRejectedReasons.FRAUDULENT ? "critical" : "warning",
      },
      [VerificationStatus.RETRY]: {
        icon: <Icon type="clock" style={{ color: getAccessibleTextColor("warning") }} size="large" />,
        label: formatMessage({ id: "containers.trust.verificationStatus.retry" }),
        // color: "warning",
        color: getAccessibleEqColor("warning"),
        textColor: getAccessibleTextColor("warning"),
        type: "warning",
      },
      [VerificationStatus.REVIEW]: {
        icon: <Icon type="clock" style={{ color: getAccessibleTextColor("info") }} size="large" />,
        label: formatMessage({ id: "containers.trust.verificationStatus.review" }),
        // color: "processing",
        color: getAccessibleEqColor("info"),
        textColor: getAccessibleTextColor("info"),
        type: "info",
      },
      [VerificationStatus.SUBMITTED]: {
        icon: <Icon type="clock" style={{ color: getAccessibleTextColor("info") }} size="large" />,
        label: formatMessage({ id: "containers.trust.verificationStatus.submitted" }),
        // color: "processing",
        color: getAccessibleEqColor("info"),
        textColor: getAccessibleTextColor("info"),
        type: "info",
      },
      [VerificationStatus.EXPIRED]: {
        icon: <Icon type="check" style={{ color: getAccessibleTextColor("default") }} />,
        label: formatMessage({ id: "containers.trust.verificationStatus.expired" }),
        // color: "default",
        color: getAccessibleEqColor("default"),
        textColor: getAccessibleTextColor("default"),
        type: "default",
      },
    }),
    [formatMessage],
  );

  const { icon, label, type } = TagInfo[status];

  return (
    <Status
      type={type as StatusType}
      prefix={<span style={{ marginRight: 5 }}>{icon}</span>}
      forceAccessibleColor
      style={{ verticalAlign: "middle" }}
    >
      {label}
    </Status>
  );
}
