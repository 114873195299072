import React from "react";
import { BackButton, Button, Divider, Notification, Space, Typography } from "components";
import {
  AccountVerification,
  BankAccountVerificationMatchType,
  BankAccountVerificationReasonType,
} from "store/actions/bankInfo";
import { useIntl } from "utils/context";
import { BaseError, RecipientType } from "@trolley/common-frontend";

export enum AccVerifResultEnum {
  AV100 = "AV100",
  AV200 = "AV200",
  AV201 = "AV201",
  AV202 = "AV202",
  AV203 = "AV203",
  AV204 = "AV204",
  AV205 = "AV205",
  AV206 = "AV206",
  AV300 = "AV300",
  AV301 = "AV301",
  AV302 = "AV302",
  AV303 = "AV303",
  AV304 = "AV304",
  AV305 = "AV305",
  AV400 = "AV400", // This is internal error it's not coming from backend, this is on frontend to surface the error.
}

// Default Result object is only to avoid compilation error when there is only error.
const defaultResult: AccountVerification = {
  reasonCode: "",
  actualName: "",
  matchType: BankAccountVerificationMatchType.NO_MATCH,
  reason: "",
  reasonType: BankAccountVerificationReasonType.REJECTED,
};

interface Props {
  result?: AccountVerification;
  recipientType?: RecipientType;
  replaceName?: (fullName: string) => void;
  error?: BaseError[];
}

// Default result object is only to avoid compilation error when there is only error.
export default function AccountVerificationResultAlert({
  result = defaultResult,
  recipientType = RecipientType.INDIVIDUAL,
  replaceName,
  error,
}: Props) {
  const { formatMessage } = useIntl();

  // If we get an error then we want AV400 to be displayed as title
  if (error) {
    result.reasonCode = AccVerifResultEnum.AV400;
  }

  function getTitle() {
    let title = "";
    switch (result.reasonCode) {
      case AccVerifResultEnum.AV100:
        title = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av100.alert.title" });
        break;
      case AccVerifResultEnum.AV200:
        title = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av200.alert.title" });
        break;
      case AccVerifResultEnum.AV201:
        title = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av201.alert.title" });
        break;
      case AccVerifResultEnum.AV202:
        title = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av202.alert.title" });
        break;
      case AccVerifResultEnum.AV203:
        title = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av203.alert.title" });
        break;
      case AccVerifResultEnum.AV204:
        title = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av204.alert.title" });
        break;
      case AccVerifResultEnum.AV205:
        title = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av205.alert.title" });
        break;
      case AccVerifResultEnum.AV206:
        title = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av206.alert.title" });
        break;
      case AccVerifResultEnum.AV300:
        title = "";
        break;
      case AccVerifResultEnum.AV301:
        title = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av301.alert.title" });
        break;
      case AccVerifResultEnum.AV302:
        title = formatMessage({
          id: `containers.bankPayoutMethod.accountVerification.av302.alert.title`,
        });
        break;
      case AccVerifResultEnum.AV303:
        title = formatMessage({
          id: `containers.bankPayoutMethod.accountVerification.av100.alert.title`,
        });
        break;
      case AccVerifResultEnum.AV304:
        title = formatMessage({
          id: `containers.bankPayoutMethod.accountVerification.av100.alert.title`,
        });
        break;
      case AccVerifResultEnum.AV305:
        title = formatMessage({
          id: "containers.bankPayoutMethod.accountVerification.av305.alert.title",
        });
        break;
      case AccVerifResultEnum.AV400:
        title = formatMessage({
          id: "containers.bankPayoutMethod.accountVerification.av400.alert.title",
        });
        break;
      default:
        title = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av206.alert.title" });
        break;
    }

    return title;
  }

  function getBodyAV100() {
    return (
      <Typography.Text>
        {formatMessage(
          { id: "containers.bankPayoutMethod.accountVerification.av100.alert.description" },
          { name: result.actualName ?? "" },
        )}
      </Typography.Text>
    );
  }

  function getBodyAV20X(resultCode: string) {
    return (
      <Typography.Text>
        {resultCode === AccVerifResultEnum.AV200 &&
          formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av200.alert.description" })}
        {resultCode === AccVerifResultEnum.AV201 &&
          formatMessage({
            id: "containers.bankPayoutMethod.accountVerification.av201.alert.description",
          })}
        {resultCode === AccVerifResultEnum.AV202 &&
          formatMessage({
            id: "containers.bankPayoutMethod.accountVerification.av202.alert.description",
          })}
        {resultCode === AccVerifResultEnum.AV203 &&
          formatMessage({
            id: "containers.bankPayoutMethod.accountVerification.av203.alert.description",
          })}
        {resultCode === AccVerifResultEnum.AV204 &&
          formatMessage({
            id: "containers.bankPayoutMethod.accountVerification.av204.alert.description",
          })}
        {resultCode === AccVerifResultEnum.AV205 &&
          formatMessage({
            id: "containers.bankPayoutMethod.accountVerification.av205.alert.description",
          })}
        {resultCode === AccVerifResultEnum.AV206 &&
          formatMessage({
            id: "containers.bankPayoutMethod.accountVerification.av206.alert.description",
          })}
      </Typography.Text>
    );
  }

  function getBodyAV30X(isMatch: boolean) {
    return (
      <>
        {recipientType === RecipientType.INDIVIDUAL && (
          <Typography.Text>
            {formatMessage({
              id: `containers.bankPayoutMethod.accountVerification.individual.${
                isMatch ? "match" : "closeMatch"
              }.alert.description`,
            })}
          </Typography.Text>
        )}
        {recipientType === RecipientType.BUSINESS && (
          <Typography.Text>
            {formatMessage({
              id: `containers.bankPayoutMethod.accountVerification.business.${
                isMatch ? "match" : "closeMatch"
              }.alert.description`,
            })}
          </Typography.Text>
        )}
        <Divider transparent />
        <Space justify="space-around">
          <BackButton
            title={formatMessage({ id: "containers.bankPayoutMethod.accountVerification.common.editAccountType" })}
          />
        </Space>
      </>
    );
  }

  function getBodyAV300() {
    return (
      <Space direction="column" align="center">
        <Typography.Text>
          {formatMessage({
            id: `containers.bankPayoutMethod.accountVerification.av300.alert.description`,
          })}
        </Typography.Text>
        {result.actualName && (
          <>
            <Typography.Text strong>{result.actualName}</Typography.Text>
            <Button
              onClick={() => {
                if (replaceName && result.actualName) replaceName(result.actualName);
              }}
            >
              {formatMessage({ id: "containers.bankPayoutMethod.accountVerification.common.useThisName" })}
            </Button>
          </>
        )}
      </Space>
    );
  }

  function getBodyAV301OrAV302() {
    return (
      <Space direction="column" align="center">
        {result.actualName && (
          <>
            <Typography.Text strong>{result.actualName}</Typography.Text>
            <Button
              onClick={() => {
                if (replaceName && result.actualName) replaceName(result.actualName);
              }}
            >
              {formatMessage({ id: "containers.bankPayoutMethod.accountVerification.common.useThisName" })}
            </Button>
          </>
        )}
      </Space>
    );
  }

  function getBodyAV305() {
    return (
      <Typography.Text>
        {formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av305.alert.description" })}
      </Typography.Text>
    );
  }

  function getBodyAV400() {
    return (
      <Typography.Text>
        {error?.[0]?.message ||
          formatMessage({
            id: "containers.bankPayoutMethod.accountVerification.av400.alert.description",
          })}
      </Typography.Text>
    );
  }

  function getBody() {
    if (result.reasonCode === AccVerifResultEnum.AV100) {
      return getBodyAV100();
    }
    if (
      [
        AccVerifResultEnum.AV200,
        AccVerifResultEnum.AV201,
        AccVerifResultEnum.AV202,
        AccVerifResultEnum.AV203,
        AccVerifResultEnum.AV204,
        AccVerifResultEnum.AV205,
        AccVerifResultEnum.AV206,
      ].includes(result.reasonCode as AccVerifResultEnum)
    ) {
      return getBodyAV20X(result.reasonCode);
    }
    if (result.reasonCode === AccVerifResultEnum.AV300) {
      return getBodyAV300();
    }

    if (result.reasonCode === AccVerifResultEnum.AV305) {
      return getBodyAV305();
    }
    if (result.reasonCode === AccVerifResultEnum.AV301 || result.reasonCode === AccVerifResultEnum.AV302) {
      return getBodyAV301OrAV302();
    }
    if (result.reasonCode === AccVerifResultEnum.AV303 || result.reasonCode === AccVerifResultEnum.AV304) {
      return getBodyAV100();
    }
    if (result.reasonCode === AccVerifResultEnum.AV400) {
      return getBodyAV400();
    }

    return getBodyAV20X(AccVerifResultEnum.AV206);
  }

  return (
    <>
      <Notification
        title={getTitle()}
        type={
          [AccVerifResultEnum.AV100, AccVerifResultEnum.AV303, AccVerifResultEnum.AV304].includes(
            result.reasonCode as AccVerifResultEnum,
          )
            ? "success"
            : "warning"
        }
      >
        {getBody()}
      </Notification>
      {[
        AccVerifResultEnum.AV301,
        AccVerifResultEnum.AV302,
        AccVerifResultEnum.AV303,
        AccVerifResultEnum.AV304,
      ].includes(result.reasonCode as AccVerifResultEnum) && (
        <>
          <Divider transparent />
          <Notification
            title={formatMessage({
              id: `containers.bankPayoutMethod.accountVerification.${recipientType}.match.alert.title`,
            })}
            type="warning"
          >
            {getBodyAV30X(false)}
          </Notification>
        </>
      )}
    </>
  );
}
