import { TicketReasonList, TicketStatus, uniqueList } from "@trolley/common-frontend";
import { Container, Divider, Grid, HelpText, Loader, Notification, Paragraph } from "components";
import dayjs from "dayjs";
import accountSummary from "locale/en/containers/accountSummary.json";
import { DocumentRequestEntry } from "pages/Tickets/DocumentRequestEntry";
import React, { useEffect, useRef, useState } from "react";
import { updateRecipient } from "store/actions/recipient";
import { useRecipient } from "store/hooks/recipient";
import { useRecipientConfig } from "store/hooks/recipientConfig";
import { useTickets } from "store/hooks/tickets";
import { BaseStatus } from "store/reducers/standardReducer";
import { useIntl } from "utils/context";
import { useDeepCompareEffect, useWindowSize } from "utils/hooks";
import AccountHolderNameInput from "./AccountHolderNameInput";
import AccountNumberInput from "./AccountNumberInput";
import AddressInput from "./AddressInput";
import CitizenshipsInput from "./CitizenshipsInput";
import DOBInput from "./DOBInput";
import GovernmentIDInput from "./GovernmentIdInput";
import NameInput from "./NameInput";
import PhoneInput from "./PhoneInput";
import StandardInput from "./StandardInput";

function renderText(text: string) {
  const lines = text.split(/\n/);

  return lines.map((l) => <div key={l}>{l}</div>);
}

function usePreviousCount(value: number) {
  const ref = useRef<number>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export default function OpenTickets() {
  const intl = useIntl();
  const { isMobile } = useWindowSize();
  const { data: recipientConfig } = useRecipientConfig();
  const { data: allTickets, status: ticketsStatus } = useTickets();
  const openTickets = allTickets.records.some((t) => t.status === TicketStatus.OPEN)
    ? allTickets.records.filter(
        (t) =>
          t.status === TicketStatus.OPEN ||
          (t.status === TicketStatus.PROVIDED && dayjs(t.createdAt).isAfter(dayjs().subtract(1, "month"))), // only ticket created in the last month
      )
    : [];
  const recipient = useRecipient();
  const [showComplete, setShowComplete] = useState(false);

  const allReasons = openTickets.flatMap((ticket) => Object.entries(ticket.reasons));
  const isPendingPayment = openTickets.some((ticket) => ticket.addressTicketOnPendingPayment);
  const uniqueActiveReasons = uniqueList(allReasons.map(([reason]) => reason as TicketReasonList));
  const prevTicketCount = usePreviousCount(openTickets.length);

  // We assume one type of document request can be requested only once per recipient.
  const documentUploadEntries = openTickets.flatMap((ticket) =>
    ticket.documentRequests.map((documentRequest) => ({
      ticket: ticket,
      documentRequest,
    })),
  );

  const allMessages = openTickets.flatMap((t) =>
    t.messages?.map((m) => (
      <Paragraph key={m.id} italic>
        {renderText(`"${m.text}"`)}
      </Paragraph>
    )),
  );

  const reasonToComponent: Record<
    Exclude<TicketReasonList, TicketReasonList.CHECK_CITY_REGION_OF_BIRTH>,
    {
      extraProps?: any;
      component: Function;
    }
  > = {
    [TicketReasonList.MISSING_DOB]: {
      component: DOBInput,
    },
    [TicketReasonList.CHECK_ACCOUNT_NUMBER]: {
      component: AccountNumberInput,
    },
    [TicketReasonList.CHECK_FULL_NAME]: {
      component: AccountHolderNameInput,
    },
    [TicketReasonList.CHECK_ADDRESS]: {
      component: AddressInput,
    },
    [TicketReasonList.CHECK_LEGAL_NAME]: {
      component: StandardInput,
      extraProps: {
        name: "legalName",
        label: intl.formatMessage({
          id: "containers.accountSummary.ticket.legalName",
          defaultMessage: accountSummary.ticket.legalName,
        }),
        value: recipient?.legalName,
        onSubmit: async (legalName: string) => {
          if (recipient) {
            await updateRecipient(recipient?.id, {
              legalName,
            });
          }
        },
      },
    },
    [TicketReasonList.CHECK_PASSPORT_NUMBER]: {
      extraProps: {
        label: intl.formatMessage({
          id: "containers.accountSummary.ticket.passportNumber",
          defaultMessage: accountSummary.ticket.passportNumber,
        }),
        /**
         *  if API is requesting passport, we prioritize showing the recipientConfig documentTypes, or default to "passport"
         * */
        idType:
          recipientConfig?.documentTypes?.[recipient?.type as "individual" | "business"]?.find(
            (type) => /pass/i.test(type.value), // this could be "PASS", or "Passport"
          )?.value || "passport",
      },
      component: GovernmentIDInput,
    },
    [TicketReasonList.CHECK_ID_NUMBER]: {
      component: GovernmentIDInput,
    },
    [TicketReasonList.CHECK_PROFILE_NAME]: {
      component: NameInput,
      extraProps: {
        label: intl.formatMessage({
          id: "containers.accountSummary.ticket.profileName",
          defaultMessage: accountSummary.ticket.profileName,
        }),
      },
    },

    [TicketReasonList.CHECK_PHONE_NUMBER]: {
      component: PhoneInput,
    },
    [TicketReasonList.CHECK_OCCUPATION]: {
      extraProps: {
        name: "occupation",
        label: "Occupation",
        value: recipient?.occupation,
        onSubmit: async (occupation: string) => {
          if (recipient) {
            await updateRecipient(recipient?.id, {
              occupation,
            });
          }
        },
      },
      component: StandardInput,
    },
    [TicketReasonList.CHECK_COUNTRY_OF_BIRTH]: {
      extraProps: {
        label: intl.formatMessage({
          id: "containers.accountSummary.ticket.placeOfBirth",
          defaultMessage: accountSummary.ticket.placeOfBirth,
        }),
        fields: ["country", "region", "city"],
        countrySet: "all",
        value: recipient?.birthplace,
        onSubmit: async ({ address }: { address: any }) => {
          if (recipient) {
            await updateRecipient(recipient?.id, {
              birthplace: address,
            });
          }
        },
      },
      component: AddressInput,
    },
    // to be DEPRECATED. We should always update the 3 fields as region list depends on country being set
    // [TicketReasonList.CHECK_CITY_REGION_OF_BIRTH]: {
    //   extraProps: {
    //     label: intl.formatMessage({
    //       id: "containers.accountSummary.ticket.placeOfBirth",
    //       defaultMessage: accountSummary.ticket.placeOfBirth,
    //     }),
    //     fields: ["city", "region"],
    //     value: recipient?.birthplace,
    //     onSubmit: async (values: { address: any }) => {
    //       if (recipient) {
    //         await updateRecipient(recipient?.id, {
    //           birthplace: { ...values.address },
    //         });
    //       }
    //     },
    //   },
    //   component: AddressInput,
    // },
    [TicketReasonList.CHECK_CITIZENSHIPS]: {
      component: CitizenshipsInput,
    },
  };

  useDeepCompareEffect(() => {
    if (prevTicketCount && ticketsStatus === BaseStatus.LOADED && openTickets.length === 0) {
      setShowComplete(true);
    }
  }, [uniqueActiveReasons]);

  function renderField(reasonType: TicketReasonList) {
    const filteredReasons = allReasons.filter(([r, resolvedDate]) => r === reasonType); // edge case where multiple tickets are open for same field. but only 1 is complete.

    const submittedDate = filteredReasons.every(([r, resolvedDate]) => resolvedDate)
      ? filteredReasons[0]?.[1]
      : undefined; // consider submitted if every ticket reason is submitted/provided

    if (recipient) {
      const componentObj = reasonToComponent[reasonType];

      return componentObj ? <componentObj.component submitted={submittedDate} {...componentObj.extraProps} /> : null;
    }

    return null;
  }

  if (showComplete) {
    return (
      <Container>
        <Notification
          closable
          type="success"
          title={intl.formatMessage({
            id: "containers.accountSummary.ticket.completeTitle",
            defaultMessage: accountSummary.ticket.completeTitle,
          })}
        >
          {intl.formatMessage({
            id: "containers.accountSummary.ticket.completeText",
            defaultMessage: accountSummary.ticket.completeText,
          })}
        </Notification>
      </Container>
    );
  }

  if (uniqueActiveReasons.length > 0 || documentUploadEntries.length > 0) {
    return (
      <Container>
        <Loader spinning={ticketsStatus === BaseStatus.LOADING}>
          <Notification
            type="warning"
            title={
              isPendingPayment
                ? intl.formatMessage({
                    id: "containers.accountSummary.ticket.titlePendingPayment",
                    defaultMessage: accountSummary.ticket.titlePendingPayment,
                  })
                : intl.formatMessage({
                    id: "containers.accountSummary.ticket.title",
                    defaultMessage: accountSummary.ticket.title,
                  })
            }
          >
            {isPendingPayment
              ? intl.formatMessage({
                  id: "containers.accountSummary.ticket.subtitlePendingPayment",
                  defaultMessage: accountSummary.ticket.subtitlePendingPayment,
                })
              : intl.formatMessage({
                  id: "containers.accountSummary.ticket.subtitle",
                  defaultMessage: accountSummary.ticket.subtitle,
                })}
            <Divider transparent />
            <Grid padding="medium">
              {allMessages.length > 0 && (
                <Grid.Item flex="1 200px" order={isMobile ? 0 : 1}>
                  <HelpText term="Additional Instructions" definition={allMessages} />
                </Grid.Item>
              )}
              <Grid.Item flex="2 350px" order={isMobile ? 1 : 0}>
                {uniqueActiveReasons.map(renderField)}

                {documentUploadEntries.map(({ documentRequest, ticket }) => (
                  <DocumentRequestEntry
                    ticket={ticket}
                    documentRequest={documentRequest}
                    isPendingPayment={isPendingPayment}
                    key={documentRequest.documentType}
                  />
                ))}
              </Grid.Item>
            </Grid>
          </Notification>
        </Loader>
      </Container>
    );
  }

  return null;
}

export type InputPopupProps = {
  submitted: string | undefined;
  label?: string;
  onSubmit?(values: any): void;
};
