import Tooltip from "components/Tooltip";
import React, { Fragment, HTMLAttributes } from "react";
import { classnames, createUseStyle, css } from "style/classname";
import { useClientIntegration, ClientContext } from "utils/context";
import { getAccessibleEqColor, getAccessibleTextColor, getContrastText } from "utils/helpers";

type Props = Omit<HTMLAttributes<HTMLSpanElement>, "prefix"> & {
  type?: "info" | "success" | "warning" | "critical" | "default";
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  tooltip?: React.ReactNode;
  children: string;
  forceAccessibleColor?: boolean;
};

export default function Status(props: Props) {
  const { type, className, children, prefix, suffix, tooltip, forceAccessibleColor, ...rest } = props;
  const client = useClientIntegration();
  const styledLabel = useStyledLabel({ props, client, forceAccessibleColor });

  const item = (
    <span className={classnames("c-status", styledLabel, className)} {...rest}>
      <Fragment key="prefix">{prefix}</Fragment>
      {children}
      <Fragment key="suffix">{suffix}</Fragment>
    </span>
  );

  return tooltip ? <Tooltip title={tooltip}>{item}</Tooltip> : item;
}

const useStyledLabel = createUseStyle<{ props: Props; client: ClientContext; forceAccessibleColor?: boolean }>(
  ({ theme, animation, ...styleProps }) => {
    const { props, client, forceAccessibleColor } = styleProps;
    const fontSize = theme.fontSize - 4;

    return css`
      font-size: ${fontSize}px;
      line-height: ${(fontSize + 8) / fontSize};
      margin-left: ${theme.marginXXS}px;
      vertical-align: text-bottom;
      display: inline-block;
      padding: 0px 0.6em;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
      white-space: nowrap;
      border-radius: 4px;
      background-color: ${() => {
        switch (props.type) {
          case "info":
            return client === "widget" && !forceAccessibleColor ? theme.colorInfo : getAccessibleEqColor("info");
          case "success":
            return client === "widget" && !forceAccessibleColor ? theme.colorSuccess : getAccessibleEqColor("success");
          case "warning":
            return client === "widget" && !forceAccessibleColor ? theme.colorWarning : getAccessibleEqColor("warning");
          case "critical":
            return client === "widget" && !forceAccessibleColor ? theme.colorError : getAccessibleEqColor("critical");
          default:
            return client === "widget" && !forceAccessibleColor ? theme.grey3 : getAccessibleEqColor("default");
        }
      }};
      color: ${() => {
        switch (props.type) {
          case "info":
            return client === "widget" && !forceAccessibleColor
              ? getContrastText(theme.colorInfo)
              : getAccessibleTextColor("info");
          case "success":
            return client === "widget" && !forceAccessibleColor
              ? getContrastText(theme.colorSuccess)
              : getAccessibleTextColor("success");
          case "warning":
            return client === "widget" && !forceAccessibleColor
              ? getContrastText(theme.colorWarning)
              : getAccessibleTextColor("warning");
          case "critical":
            return client === "widget" && !forceAccessibleColor
              ? getContrastText(theme.colorError)
              : getAccessibleTextColor("critical");
          default:
            return client === "widget" && !forceAccessibleColor
              ? getContrastText(theme.grey3)
              : getAccessibleTextColor("default");
        }
      }};

      @media print {
        border: 1px solid #000;
      }
    `();
  },
);
